import Link from "next/link"
import Image from "next/image"
import { ArrowRightIcon } from "@heroicons/react/outline"
import Header from "components/public/Header"
import Container from "components/public/Container"
import Button from "components/Button"
import Footer from "components/public/Footer"
import Subtitle from "components/public/Subtitle"
import ImageTextSection from "components/public/ImageTextSection"

const internalSections = [
	{
		title: "Yonderlife Memories",
		subtitle: "In the end, your memories define your life.",
		video: "https://www.youtube.com/embed/M6XmZ-eX7iU?controls=0",
		image: "/home/casual-life-3d-boy-sitting-on-knees-and-reading-book.png",
		features: ["Live it", "Capture it", "Store it", "Share it", "Pass it on"],
		buttonText: "Sign up",
		buttonHref: "/register",
	},
	{
		title: "Yonderlife About Me",
		subtitle: "It’s a blank canvas.",
		video: "https://www.youtube.com/embed/nVrF0YWnB7E?controls=0",
		image: "/home/casual-life-3d-reading-5.png",
		features: [
			"Say what you feel",
			"Leave your funeral wishes",
			"Detail your affairs",
			"Write your autobiography",
			"Tell your story",
		],
		buttonText: "Sign up",
		buttonHref: "/register",
	},
	{
		title: "Yonderlife Accounts",
		subtitle: "Document your affairs. Help your family.",
		video: "https://www.youtube.com/embed/4CJ4MfGH8Lg?controls=0",
		image: "/home/casual-life-3d-meditation-crystal.png",
		features: [
			"Insurance policies",
			"Financial accounts",
			"Utility services",
			"All other accounts, subscriptions, clubs and societies",
		],
		buttonText: "Sign up",
		buttonHref: "/register",
	},
	{
		title: "Yonderlife Assets",
		subtitle: "Money matters when you have danced your last dance.",
		video: "https://www.youtube.com/embed/MTzfJkjz1xA?controls=0",
		image: "/home/casual-life-3d-girl-considers-the-invested-funds 2.png",
		features: [
			"Create an estate inventory",
			"List your life’s assets",
			"Upload images and documents",
			"Enter a value",
			"Estimate estate value",
		],
		buttonText: "Sign up",
		buttonHref: "/register",
	},
	{
		title: "Yonderlife Executor",
		subtitle: "Be a better executor.",
		video: "https://www.youtube.com/embed/nH2iGJ5MdtQ?controls=0",
		image: "/home/casual-life-3d-girl-boy-poses-1.png",
		features: [
			"Save money",
			"Create a probate file",
			"Value an estate",
			"Assist organising a funeral",
			"Help obtain grant of probate",
			"Help administer an estate",
			"Personalise your way",
			"Assign responsibilities",
			"Enable reminders",
			"Upload files and images",
			"Work alone or with family",
			"Share with probate solicitors",
			"Complete audit history",
		],
		buttonText: "Sign up",
		buttonHref: "/register",
	},
]

const testimonials = [
	{
		title: "Yonderlife enables me to be clear with my wishes.",
		text: "My daughters are fully aware of how I want to live a full life. Yonderlife enables me to be clear with my wishes. I have shared what I want with both of them and they know where everything is including my will. I can update my life at anytime and they see it automatically.",
		authors: ["Beatriz, 69"],
	},
	{
		title:
			"Yonderlife enables us to help mum with the variety of challenges that arise with extreme memory issues",
		text: "Our mum is dealing with Alzheimers. As her memory deteriorates we have captured her life, our family history and many things we never knew about.  We have uploaded both our power of attorneys and a copy of mum’s will so we have been able to help mum with the variety of challenges that arise with extreme memory issues. Yonderlife helps massively if you all live in different areas and have busy lives.",
		authors: ["Craig, 52", "James, 50", "Liane, 48", "Chloe, 46", "Lynne, 75"],
	},
	{
		title:
			"Yonderlife enabled us to have constructive discussions about mum and dad’s estate and what mum really wants.",
		text: "Mum’s been on her own for some years and along with my brother we needed to manage all our discussions as we live in different part of the the country. We use Yonderlife for mum and it has enabled us to have constructive discussions about mum and dad’s estate and what mum really wants.",
		authors: ["Tim, 49", "Barbara, 84"],
	},
]

export default function Home() {
	const Hero = () => {
		return (
			<div className="relative w-full bg-gray-50 lg:bg-white">
				<Container>
					<div className="relative z-10 grid items-center lg:grid-cols-12 gap-6">
						<div className="lg:col-span-7 my-8 md:my-16 lg:my-32 2xl:my-56">
							<h1 className="font-bold font-display tracking-tighter leading-[0.9] text-gray-800 text-4xl lg:text-7xl lg:pr-12 2xl:pr-0">
								Remember the past, <br />
								plan for the future, <br />
								live for today.
							</h1>
							<p className="mt-6 text-xl md:text-2xl text-gray-600 md:w-2/3">
								Use Yonderlife to share your memories, tell your story, document
								your affairs and value your assets.
							</p>
							<div className="flex flex-col items-stretch space-y-2 sm:space-y-0 sm:flex-row sm:items-center sm:justify-start sm:space-x-2 mt-8">
								<div>
									<Button href="/register" color="yellow" size="lg" block>
										<span>Sign up</span>
										<ArrowRightIcon className="w-4 h-4 ml-3" />
									</Button>
								</div>
							</div>
						</div>
						<div className="lg:col-span-5 flex justify-end 2xl:ml-24 2xl:-mr-24">
							<div className="lg:scale-125 2xl:scale-150">
								<Image
									src="/home/casual-life-3d-likes.png"
									width="600"
									height="400"
									className="object-contain overflow-visible"
									priority
								/>
							</div>
						</div>
					</div>
				</Container>
			</div>
		)
	}

	const Internals = () => {
		return internalSections.map((section, index) => (
			<section
				id="valuation"
				className={`${
          index === 0 ? "bg-gradient-to-b from-gray-50 to-yellow-50" : 
					(index % 2 !== 0
						? "bg-gradient-to-b from-yellow-50 to-white"
						: "bg-gradient-to-t from-yellow-50 to-white")
				}`}
			>
				<ImageTextSection {...section} swap={index % 2 !== 0} />
			</section>
		))
	}

	const Testimonials = () => {
		return (
			<div className="bg-gradient-to-b from-white to-gray-50 py-16 md:py-24">
				<Container>
					<div className="text-gray-800">
						<div className="text-center">
							<Subtitle>What people are saying</Subtitle>
						</div>
					</div>
					<div className="grid md:grid-cols-3 gap-6 items-start mt-10">
						{testimonials.map((testimonial) => (
							<div
								key={testimonial.title}
								className="flex flex-col bg-white border border-gray-100 shadow-xl shadow-gray-600/10 rounded-lg p-6"
							>
								{/* <h3>{testimonial.title}</h3> */}
								<p className="text-gray-500 text-sm leading-6">
									"{testimonial.text}"
								</p>
								<div className="flex flex-wrap gap-2 mt-4">
									{testimonial.authors.map((author) => (
										<div
											key={author}
											className="flex-shrink-0 bg-gray-100 text-gray-500 rounded px-2 py-1 text-xs"
										>
											{author}
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				</Container>
			</div>
		)
	}

	const Community = () => {
		const Post = ({ title, image, description, href }) => (
			<div>
				<div>
					<img
						src={image}
						className="w-full aspect-video"
					/>
				</div>
				<div className="mt-5">
					<h3 className="font-bold text-lg sm:text-2xl text-gray-800">
						{title}
					</h3>
					<p className="text-gray-500 mt-3">{description}</p>
					<Link href={href}>
						<a target="_blank" className="block underline text-gray-800 mt-4">
							See how we can help
						</a>
					</Link>
				</div>
			</div>
		)

		return (
			<section
				id="valuation"
				className="bg-gradient-to-b from-yellow-50 to-white"
			>
				<div className="py-16 md:py-24">
					<Container>
						<div className="text-center flex flex-col items-center space-y-6">
							<div className="text-gray-800">
								<Subtitle>We are building a Community</Subtitle>
							</div>
							<p className="max-w-3xl mx-auto text-lg text-gray-500">
								What to do when someone dies
							</p>
							<div className="w-full flex flex-col space-y-4 items-stretch sm:space-y-0 sm:gap-0 sm:flex-row sm:items-center sm:justify-center sm:space-x-4">
								<div>
									<Button
										href="https://community.yonderlife.co.uk/"
										color="yellow"
										size="lg"
										block
									>
										Read more
									</Button>
								</div>
								<div>
									<Button
										href="https://community.yonderlife.co.uk/contact"
										color="outline"
										size="lg"
										block
									>
										I want to contribute
									</Button>
								</div>
							</div>
						</div>
						<div className="grid md:grid-cols-3 gap-8 mt-24">
							<Post
								title="Coping with Alzheimers, Dementia or another incapacity issue?"
								description="We want to help with a challenge that our family is meeting.  My name is James and this is my mum, Lynne. Between five and six years ago..."
								image="https://community.yonderlife.co.uk/content/images/size/w2000/2022/07/alzheimers.png"
								href="https://community.yonderlife.co.uk/coping-with-alzheimers-dementia-or-another-incapacity-issue/"
							/>
							<Post
								title="You can use Yonderlife for someone else"
								description="Yonderlife is built for you. Choose to use it yourself or ask a loved one to help. Sign up for free."
								image="https://community.yonderlife.co.uk/content/images/size/w2000/2022/06/My-project-copy--27-.png"
								href="https://community.yonderlife.co.uk/how-do-i-use-yonderlife-for-someone-else/"
							/>
							<Post
								title="Arranging a funeral, obtaining grant of probate, administering an estate?"
								description="You may be a nominated executor or a concerned family member.  You may be starting to understand what needs to happen..."
								image="https://images.unsplash.com/photo-1568540837353-35bac697a425?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDE4MHx8c3VwcG9ydHxlbnwwfHx8fDE2NTY0OTA4NTI&ixlib=rb-1.2.1&q=80&w=1200"
								href="https://community.yonderlife.co.uk/arranging-a-funeral-obtaining-grant-of-probate-administering-an-estate/"
							/>
						</div>
					</Container>
				</div>
			</section>
		)
	}

	return (
		<>
			<Header />

			{/* Intro + banner */}
			<Hero />

			{/* Testominals */}
			<Testimonials />

			{/* Internals */}
			<Internals />

			{/* Community */}
			<Community />

			<Footer />
		</>
	)
}
