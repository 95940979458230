import Image from "next/image"
import { useState } from "react"
import { CheckIcon } from "@heroicons/react/outline"
import Button from "components/Button"
import Container from "./Container"
import Subtitle from "./Subtitle"

export default function ImageTextSection({
	title,
	subtitle,
	features,
	image,
	feature,
	buttonHref,
	buttonText,
	video,
	swap,
}) {
	const [showVideo, setShowVideo] = useState(false)
	return (
		<div className="py-16 md:py-24">
			<Container>
				<div className="grid gap-6 lg:grid-cols-2 lg:gap-4 lg:items-center">
					<div className={`order-2 ${swap ? "lg:order-1" : ""}`}>
						<div className="text-gray-800">
							<Subtitle>{title}</Subtitle>
						</div>
						<p className="mt-4 max-w-3xl text-lg text-gray-500">{subtitle}</p>
						<div className="space-y-2 mt-6">
							{features.map((feature) => (
								<div key={feature} className="flex items-center space-x-2">
									<div>
										<CheckIcon className="w-5 h-5 text-yellow-500" />
									</div>
									<div className="text-gray-800">{feature}</div>
								</div>
							))}
						</div>
						<div className="flex flex-col items-stretch sm:items-center sm:flex-row sm:justify-start mt-6 lg:mt-8">
							<div className="flex gap-3">
								<Button href={buttonHref} size="lg" color="yellow">
									{buttonText}
								</Button>
                {
                  video && (
                    <Button size="lg" color="outline" onClick={() => setShowVideo(true)}>
                      Watch video
                    </Button>
                  )
                }
							</div>
						</div>
					</div>
					<div className={`relative order-1 ${swap ? "lg:order-2" : ""}`}>
						{feature ? (
							feature
						) : (showVideo && video) ? (
							<iframe
								src={`${video}&autoplay=1`}
								title="YouTube video player"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
                className="w-full aspect-video"
							></iframe>
						) : (
							<button
								type="button"
								onClick={() => setShowVideo(true)}
								className="p-8 lg:scale-125"
							>
								<Image
									src={image}
									width="600"
									height="400"
									className="object-contain overflow-visible"
								/>
							</button>
						)}
					</div>
				</div>
			</Container>
		</div>
	)
}
